import colors from './colors';

export function returnCor(temaCor, chave, comSombra, backgroundTransparent) {
  let rgb = {};

  if (temaCor[chave]) {
    rgb = colors.hexToRgb(temaCor[chave]);
  }

  if (backgroundTransparent) {
    return `background-color: rgba(${rgb.r}, ${rgb.g}, ${rgb.b},0.3);`;
  }

  if (comSombra) {
    return `
      box-shadow: 0 5px 15px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9);
      background-color: ${temaCor[chave]};
    `;
  } else {
    return `
      background-color: ${temaCor[chave]};
    `;
  }
}
