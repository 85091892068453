<template>
  <b-row class="mt-5 text-center" v-if="erroMensagem">
    <b-col>
      <img :src="logo" style="max-width: 90%" />
      <div class="alert alert-warning">{{ erroMensagem }}</div>
      <b-button @click="retry()">Tentar Novamente</b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'erro-conexao',
  props: {
    logo: String,
    erroMensagem: String,
  },
  methods: {
    retry() {
      this.$emit('retry');
    },
  },
};
</script>
