exports.buscaDadosEmpresa = () => {
  return new Promise((resolve) => {
    const logo = localStorage.getItem('logo') || null;
    const token = localStorage.getItem('user-token') || null;
    const strDadosRes = localStorage.getItem('dadosRes') || '{}';
    const strTemaCor = localStorage.getItem('temaCor') || '{}';

    const dadosRes = JSON.parse(strDadosRes);
    const temaCor = JSON.parse(strTemaCor);

    const result = {
      logo,
      token,
      dadosRes,
      temaCor,
    };

    resolve(result);
  });
};
