import service from './../services';
import utils from './utils';

/**
 * Solicita o envio do códiog Novamente
 * @param {object} data dados a serem enviado para api
 * @param {object} objToast objeto `this.$bvToast`
 * @returns {Promise}
 */
export function enviarCodigoNovamente(data, objToast) {
  return new Promise((resolve, reject) => {
    service
      .post('Login', 'enviarCodigoNovamente', data)
      .then((res) => {
        utils.showToast(
          objToast,
          'O código de Verificação foi enviado',
          'success',
        );
        resolve(res.data);
      })
      .catch((e) => {
        utils.showToast(objToast, e, 'danger');
        reject();
      });
  });
}
